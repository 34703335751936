<template>
  <v-container fluid>
    
    <v-toolbar flat>
     <v-toolbar-title>Purchase Orders</v-toolbar-title>
     <v-divider class="mx-2" inset vertical></v-divider>
     <v-btn elevation="2" text outlined class="blue darken-2 ml-1" @click="load()"> <v-icon color="white">mdi-refresh</v-icon> </v-btn>
     <v-switch class="ml-2" v-model="exchangeRateDisplay" :label="exchangeRateDisplay ? 'USD':'HNL'" hide-details></v-switch>
     <v-spacer></v-spacer>
     <v-text-field v-model="posearch" label="Search" hide-details></v-text-field>
    </v-toolbar>

    <v-divider inset></v-divider>

    <v-row dense>
      <v-col cols="12">
        <v-data-table
          id="potable"
          :headers="poheaders"
          :items="items"
          :items-per-page="5"
          @click:row="highlightrow"
          :loading="itemsloading"
          item-key="id"
          single-select
          :dense="itemdense"
          :search="posearch"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus'
          }">

          <template v-slot:[`item.info`]="{ item }">
            <v-menu offset-y offset-x open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="float-right" small style="padding-top:1px">mdi-information-outline</v-icon>
              </template>
              <v-list>
                  <v-list-item> <v-list-item-title>{{ item.c1 }}</v-list-item-title> </v-list-item> 
                  <v-list-item> <v-list-item-title>{{ item.c2 }}</v-list-item-title> </v-list-item> 
                  <v-list-item> <v-list-item-title>{{ item.c3 }}</v-list-item-title> </v-list-item> 
                  <v-list-item> <v-list-item-title>{{ item.c4 }}</v-list-item-title> </v-list-item> 
              </v-list>
            </v-menu>
          </template>

          <template v-slot:[`item.total`]="{ item }">
            <b>{{item.total.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}}</b> <span style="color:#888">({{item.ratio.toFixed(2)}}%)</span>
          </template>
           <template v-slot:[`item.firstitem`]="{ item }">
            <div style="white-space:nowrap!important;overflow:hidden!important;text-overflow:ellipsis!important;width:200px!important">{{item.parts[0].description}}</div>
          </template>
          <template v-slot:[`item.partCount`]="{ item }">
            <v-chip label class="ma-2" color="green lighten-2-" text-color="white">{{item.partCount}}</v-chip>
          </template>
          <template v-slot:[`body.append`]>
            <tr>
              <td colspan="6"></td>
              <td class="text-right"><b>{{pototal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}}</b> <span style="color:#888">(100%)</span></td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-data-table
          :headers="partsheaders"
          :items="parts"
          :loading="partsloading"
          :dense="partsdense"
          :items-per-page="10"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus'
          }">
          <template v-slot:top>
            <v-toolbar color="blue darken-3 elevation-0" dark dense>
              <v-toolbar-title>{{potag}}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-toolbar-title>{{potag2}}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-switch v-model="partsdense" hide-details></v-switch>
            </v-toolbar>
          </template>
          <template v-slot:[`item.cost`]="{ item }">
            <b>{{item.cost.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}}</b>
          </template>
          <template v-slot:[`item.total`]="{ item }">
            <b>{{item.total.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}}</b>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

const Honduran_Exchange_Rate_FIXED = 25

import async from 'async'
import axios from 'axios'
export default {
  name: "OpenPurchase",
  data: () => ({
    exchangeRateDisplay: true,
    items: [],
    parts: [],
    poheaders: [
      { text: "PO#", align: "left", sortable: true, value: "ponumber", width: "75px" },
      { text: "", align: "left", value: "info", cellClass: "narrow" },
      { text: "Company Name", align: "left", sortable: true, value: "vendor", width: "400px" },
      { text: "ETA SMG", align: "right", sortable: true, value: "reqdate" },
      { text: "First Item", align: "left", sortable: true, value: "firstitem" },
      { text: "Parts", align: "right", sortable: true, value: "partCount" },
      { text: "Total", align: "right", sortable: true, value: "total", width: "200px" }
    ],
    partsheaders: [
      { text: "Item", value: "item" },
      { text: "Description", value: "description" },
      { text: "Doc Date", align: "right", value: "docdate" },
      { text: "Quantity", align: "right", value: "quantity" },
      { text: "Remaining", align: "right", value: "remaining" },
      { text: "UOM", value: "uom" },
      { text: "Cost", value: "cost", align: "right" },
      { text: "Total", value: "total", align: "right" }
    ],
    partsloading: false,
    itemsloading: false,
    itemdense: false,
    partsdense: false,
    posearch: "",
    potag: "",
    potag2: "",
    databaseerror: false,
    pototal: 0,
    comments: '',
    pocomment: '',
    commentlabel: '',
    showcomments: true
  }),
  mounted: function() {
    this.load()
  },
  computed: {},
  methods: {
    build_comments (i) {
      return `${i.COMMENT_1}\r\n${i.COMMENT_2}\r\n${i.COMMENT_3}\r\n${i.COMMENT_4}`
    },
    load: function() {
      this.itemsloading = true
      this.items = []
      async.waterfall(
        [
          async.apply(function(that, callback) {
            axios
              .get(`${that.$store.getters.NODEURL}/report/smg`,
                { withCredentials: true })
              .then(response => {

                let oldpart = 0
                // let pcomment = ''

                for(let i = 0; i < response.data.length; ++i) {

                  let o = response.data[i]

                  if(response.data[i].PONUMBER != oldpart) {
                    let parts = that.makeparts(response.data[i].PONUMBER, response.data)

                    // pcomment = `${o.COMMENT_1===null?'':o.COMMENT_1}\n${o.COMMENT_2===null?'':o.COMMENT_2}\n`
                    // pcomment += `${o.COMMENT_3===null?'':o.COMMENT_3}\n${o.COMMENT_4===null?'':o.COMMENT_4}\n`

                    that.items.push({
                      id: i,
                      ponumber: o.PONUMBER,
                      vendor: o.VENDNAME,
                      reqdate: o.REQDATE,
                      c1: o.COMMENT_1,
                      c2: o.COMMENT_2,
                      c3: o.COMMENT_3,
                      c4: o.COMMENT_4,
                      prstadcd: o.PRSTADCD,
                      parts: parts,
                      partCount: parts.length,
                      total: parseInt(that.sumparts(parts)),
                      ratio: 0
                    })
                    oldpart = o.PONUMBER
                  }
                }
                that.pototal = 0
                for(let i = 0; i < that.items.length; ++i) { that.pototal += that.items[i].total }
                for(let i = 0; i < that.items.length; ++i) { that.items[i].ratio +=  (that.items[i].total / that.pototal) * 100 }
              })
              /* eslint-disable no-unused-vars */
              .catch(e => {
                that.items = that.parts = []
                that.databaseerror = true
              })
              .finally(() => {
                that.itemsloading = false
                callback(null, that)
              })
          }, this)
        ],
        function(err, that) { }
      )
    },
    highlightrow: function(item, row) {
      this.partsloading = true
      row.select(true)
      setTimeout(() => {
        this.parts = item.parts
        this.partsloading = false
        this.potag = item.ponumber
        this.potag2 = `${item.vendor} ${item.total.toLocaleString("en-US", { style: "currency", currency: "USD" })}`}, 650)
        this.pocomment = item.comment
        this.commentlabel = `${item.ponumber}`
    },
    sumparts: function(parts) {
      let total = 0
      for (var p = 0; p < parts.length; ++p) {
        total += parts[p].total
      }
      return total
    },
    makeparts: function(ponumber, data) {
      let parts = [], quantity = 0, cost = 0
      console.log(this.exchangeRateDisplay)
      for (var t = 0; t < data.length; ++t) {
        if(data[t].PONUMBER === ponumber) {
          quantity = data[t].QTYORDER
          cost = data[t].UNITCOST / (this.exchangeRateDisplay ? Honduran_Exchange_Rate_FIXED : 1) 
          parts.push({
            item: data[t].ITEMNMBR,
            description: data[t].ITEMDESC,
            shipmethod: data[t].SHIPMTHD,
            docdate: data[t].DOCDATE,
            quantity: data[t].QTYORDER.toFixed(2),
            remaining: data[t].QTYUNCMTBASE.toFixed(2),
            uom: data[t].UOFM,
            cost: cost,
            total: Math.round(cost * quantity)
          })
        }
      }
      return parts
    }
  }
}
</script>

<style>
tr.v-data-table__selected { background: #fff59d !important; color: #000; }
#potable table tbody tr td:nth-child(2) { border-right: solid 1px #bbb; }
.narrow { max-width: 40px!important; width: 40px!important; }
</style>

<style scoped></style>