var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Purchase Orders")]),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}}),_c('v-btn',{staticClass:"blue darken-2 ml-1",attrs:{"elevation":"2","text":"","outlined":""},on:{"click":function($event){return _vm.load()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-refresh")])],1),_c('v-switch',{staticClass:"ml-2",attrs:{"label":_vm.exchangeRateDisplay ? 'USD':'HNL',"hide-details":""},model:{value:(_vm.exchangeRateDisplay),callback:function ($$v) {_vm.exchangeRateDisplay=$$v},expression:"exchangeRateDisplay"}}),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Search","hide-details":""},model:{value:(_vm.posearch),callback:function ($$v) {_vm.posearch=$$v},expression:"posearch"}})],1),_c('v-divider',{attrs:{"inset":""}}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"id":"potable","headers":_vm.poheaders,"items":_vm.items,"items-per-page":5,"loading":_vm.itemsloading,"item-key":"id","single-select":"","dense":_vm.itemdense,"search":_vm.posearch,"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus'
        }},on:{"click:row":_vm.highlightrow},scopedSlots:_vm._u([{key:"item.info",fn:function(ref){
        var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"float-right",staticStyle:{"padding-top":"1px"},attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(item.c1))])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(item.c2))])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(item.c3))])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(item.c4))])],1)],1)],1)]}},{key:"item.total",fn:function(ref){
        var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.total.toLocaleString('en-US', { style: 'currency', currency: 'USD' })))]),_vm._v(" "),_c('span',{staticStyle:{"color":"#888"}},[_vm._v("("+_vm._s(item.ratio.toFixed(2))+"%)")])]}},{key:"item.firstitem",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap!important","overflow":"hidden!important","text-overflow":"ellipsis!important","width":"200px!important"}},[_vm._v(_vm._s(item.parts[0].description))])]}},{key:"item.partCount",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"label":"","color":"green lighten-2-","text-color":"white"}},[_vm._v(_vm._s(item.partCount))])]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"6"}}),_c('td',{staticClass:"text-right"},[_c('b',[_vm._v(_vm._s(_vm.pototal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })))]),_vm._v(" "),_c('span',{staticStyle:{"color":"#888"}},[_vm._v("(100%)")])])])]},proxy:true}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.partsheaders,"items":_vm.parts,"loading":_vm.partsloading,"dense":_vm.partsdense,"items-per-page":10,"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus'
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":"blue darken-3 elevation-0","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.potag))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.potag2))]),_c('v-spacer'),_c('v-switch',{attrs:{"hide-details":""},model:{value:(_vm.partsdense),callback:function ($$v) {_vm.partsdense=$$v},expression:"partsdense"}})],1)]},proxy:true},{key:"item.cost",fn:function(ref){
        var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.cost.toLocaleString('en-US', { style: 'currency', currency: 'USD' })))])]}},{key:"item.total",fn:function(ref){
        var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.total.toLocaleString('en-US', { style: 'currency', currency: 'USD' })))])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }